import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import Layout from "../../components/Layout";
import { Link, StaticQuery } from "gatsby";

const BlogIndexPage = ({ data }) => (
  <div className="columns is-multiline">
    {data.allMarkdownRemark.nodes
      .sort(function(a, b) {
        return new Date(b.frontmatter.date) - new Date(a.frontmatter.date);
      })
      .map((post, i) => (
        <Fragment key={i}>
          <div className="column is-6">
            <div className="BlogRoll__img-container">
              <span className="BlogRoll__date">{post.frontmatter.date}</span>
              <img src={post.frontmatter.image.childImageSharp.fluid.src} />
            </div>
          </div>
          <div className="column is-6">
            <h2 className="is-size-3">
              {post.frontmatter.title}
              <br />
              {post.frontmatter.subTitle}
            </h2>
            <p className="is-size-5">{post.frontmatter.description}</p>
            <Link to={post.fields.slug} className="btn btn-outline">
              Read More
            </Link>
          </div>
        </Fragment>
      ))}
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(filter: { fields: { slug: { regex: "/blog/" } } }) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              subTitle
              date(formatString: "MMM, YYYY")
              description
              image {
                id
                childImageSharp {
                  id
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader title={"Blog"} paintColor={"green"} />
        <div id="blog-splatter">
          <section className="section">
            <div className="container">
              <div className="content">
                <BlogIndexPage data={data} />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )}
  />
);

// graphql query for blog posts
// {
//   allMarkdownRemark(filter: {fields: {slug: {regex: "/blog/"}}}) {
//     nodes {
//       fields {
//         slug
//       }
//       frontmatter {
//         title
//         templateKey
//         subheading
//         subTitle
//         date
//         description
//         path
//         heading
//         image {
//           id
//           childImageSharp {
//             id
//             fluid {
//               src
//             }
//           }
//         }
//       }
//     }
//   }
// }
